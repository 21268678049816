<template>
  <v-dialog v-model="dialog" max-width="290">
    <v-card>
      <v-card-title class="text-h5">
        {{ this.dialogTitle }}
      </v-card-title>
      <v-card-text>{{ this.dialogText }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="dialog = false">
          {{ this.dialogNo }}
        </v-btn>
        <v-btn color="green darken-1" text @click="handleYes">
          {{ this.dialogYes }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",
  data() {
    return {
      dialog: false,
    };
  },
  created() {},
  props: {
    id: {
      type: Number,
      required: true,
    },
    dialogTitle: {
      type: String,
      required: true,
    },
    dialogText: {
      type: String,
      required: true,
    },
    dialogYes: {
      type: String,
      required: true,
    },
    dialogNo: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleYes() {
      this.dialog = false;
      this.$emit("dialog-yes", this.id);
    },
  },
};
</script>

<style scoped>
.person-name {
  word-break: break-word;
}
.photo-container {
  margin-right: 15px;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
.access-detail-p {
  font-size: 1.5em;
}

.access-detail-blk {
  margin-top: 12px;
}

.access-subtitle {
  margin-left: 15px;
}

.access-detail-phones {
  font-size: 1.5em;
  margin-left: 30px;
}
</style>
